import { useEffect, useMemo, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import countriesList from "i18n-iso-countries";

import { useFormik } from "formik";
import { RestAPI } from "@aws-amplify/api-rest";
import ReCAPTCHA from "react-google-recaptcha";

import { Autocomplete, Button, Divider, Link, TextField } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Auth } from "@aws-amplify/auth";

import apiVersions from "../../resources/apiVersions.json";

const latestVersion = apiVersions.DocumentationVersionsOptions.find(v => v.latest) || apiVersions.DocumentationVersionsOptions[0];

const theme = createTheme();

const MIN_PASS_LENGTH = 8;
const SITE_KEY = "6Lc7pOoZAAAAAJq0xd5mcYXwwrmfepkOtxVODIMC";

const registerSchema = {
    nombreEmpresa: "string",
    direccion: "string",
    direccion_linea_2: "string,optional",
    city: "string",
    state: "string,optional",
    country: "country",
    admin_contact: "string",
    email_admin_contact: "email",
    fono_admin_contact: "phone",
    technical_contact: "string",
    email_technical_contact: "email",
    representative: "string",
    motive: "string",
};

// ver que onda el numero
const validate = (values) => {
    const errors = Object.keys(registerSchema).reduce((res, key)=> {
        const [type, optional] = registerSchema[key].split(",");

        if (optional !== "optional") {
            if (type === "phone") {
                if (!Number.isFinite(values[key])) res[key] = "required";
            } else if (type === "country") {
                if (!values[key]?.descripcion) res[key] = "required";
            } else {// strings
                if (!values[key]?.length) res[key] = "required";
            }
        }

        if (type === "email" && !res[key] && !/^.+@.+\.[A-Z]{2,4}$/i.test(values[key])) {
            res[key] = "invalid";
        } else if (type === "phone" && !res[key] && values[key] <= 0) {
            res[key] = "invalid";
        }
        return res;
    },{});

    return errors;
};

const RequestForm = ({ setShowLoginState }) => {
    const reCaptchaRef = useRef(null);
    const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
    const asyncScriptOnLoad = useMemo(() => () => setRecaptchaLoaded(true), []);

    const { t, i18n } = useTranslation();
    const lang = i18n.language;
    const {
        values, touched, errors, isSubmitting,
        handleSubmit, handleChange, handleBlur, setFieldValue
    } = useFormik({
        initialValues: Object.keys(registerSchema).reduce((res, key)=> {
            const type = registerSchema[key].split(",")[0];
            res[key] = type === "phone" ? "" : (type === "country" ? null : "");
            return res;
        }, {}),
        validate,
        onSubmit: async (values) => {
            const token = await reCaptchaRef.current.executeAsync();

            await RestAPI.post("apiApplicationNoAuth", "/register", { body: { ...values, token } });
            setShowLoginState("LOGIN");
        },
    });

    const [countriesBase, setCountriesBase] = useState([]);
    useEffect(() => {
        RestAPI.get("apiApplicationNoAuth", "/countries", {}).then((countries) => {
            // Filter "Serbia and Montenegro". Is not handled by countriesList since it doesn't exist (we don have "Serbia" and "Montenegro" as different countries)
            const SERBIA_MONTENEGRO = 205;
            setCountriesBase(countries.filter(c => c.tipo_pais_id !== SERBIA_MONTENEGRO));
        });
    }, []);
    const countries = useMemo(() => {
        const ret = countriesBase.map(c => {
            // Handle AN manually. It doesn't exist anymore, but we dont have the new countries, so keep it
            const AN = 9;
            if (c.tipo_pais_id === AN) return { ...c, descripcion: "Netherlands Antilles" };
            return { ...c, descripcion: countriesList.getName(c.codigo, lang) ?? c.descripcion };
        });

        ret.sort(function (a, b) {
            return a.descripcion.localeCompare(b.descripcion, lang);
        });
        return ret;
    }, [countriesBase, lang]);

    const getTextField = (field, extraProps) => {
        const errorLabel = (touched[field] && errors[field]) || null;
        return (
            <TextField
                margin="normal"
                fullWidth
                id={field}
                label={t(`login.request.${field}`)}
                name={field}
                value={values[field]}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errorLabel)}
                helperText={errorLabel ? t(`form.errors.${errorLabel}`) : null}
                {...extraProps}
            />
        );
    };
    return (
        <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleSubmit}>
            <Typography>
                {t("login.request.msg")}
            </Typography>
            {getTextField("nombreEmpresa")}
            {getTextField("direccion")}
            {getTextField("direccion_linea_2")}

            {getTextField("city")}
            {getTextField("state")}
            <Autocomplete
                fullWidth
                id="country"
                value={values.country ?? null}
                onChange={(_e, value) => setFieldValue("country", value)}
                onBlur={handleBlur}
                options={countries}
                renderInput={(params) => {
                    const errorLabel = (touched.country && errors.country) || null;

                    return (
                        <TextField
                            {...params}
                            margin="normal" label={t("login.request.country")} name="country"
                            error={Boolean(errorLabel)}
                            helperText={errorLabel ? t(`form.errors.${errorLabel}`) : null}
                        />
                    );
                }}
                getOptionLabel={(country) => country?.descripcion ?? ""}
            />

            {getTextField("admin_contact")}
            {getTextField("email_admin_contact", { autoComplete: "email" })}
            {getTextField("fono_admin_contact", { type: "number" })}
            {getTextField("technical_contact")}
            {getTextField("email_technical_contact", { autoComplete: "email" })}
            {getTextField("representative")}
            {getTextField("motive")}

            <Typography component="p" align="center">
                { }
            </Typography>
            <Button
                fullWidth
                disabled={!recaptchaLoaded || isSubmitting}
                variant="contained"
                type="submit"
                sx={{ mt: 3, mb: 1 }}
            >
                {t("login.request.submit")}
            </Button>
            <ReCAPTCHA
                size="invisible"
                ref={reCaptchaRef}
                sitekey={SITE_KEY}
                asyncScriptOnLoad={asyncScriptOnLoad}
            />

            <Button
                fullWidth
                variant="contained"
                color="secondary"
                sx={{ mb: 3 }}
                onClick={() => setShowLoginState("LOGIN")}
            >
                {t("login.request.back")}
            </Button>
        </Box>
    );
};

const Login = ({ dcEmail }) => {
    const { t } = useTranslation();
    //datos del login
    const [userEmail, setUser] = useState(dcEmail || "");
    const [userPass, setPassword] = useState(" ");

    //mensaje de error y var para renderizar login o formForgotpass
    //const msgInfoForgot = t("login.msgInfoForgot");
    const pHolderCode = t("login.pHolderCode");
    const pHolderPass = t("login.pHolderPass");
    const pHolderPassConfirm = t("login.pHolderPassConfirm");
    const changePassword = t("login.changePassword");

    const [showPassForNewUser, setShowPassForNewUser] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);

    const [showUserState, setShowUserState] = useState({
        showInvalidUser: false,
        showMsgEmail: false,
        showMsgPass: false,
    });

    const [showState, setShowState] = useState({
        showUserNotFound: false,
        showCodeSent: false,
        showEnterCode: false,
        showInvalidCode: false,
        showPassLength: false,
        showPassMatch: false,
        showCodeException: false,
        showExpiredCodeException: false,
        showLimitExceededException: false,
        showInvalidParameterException: false,
        showUserNotFoundException: false,
        showDefaultError: false,
        showPassChanged: false,
    });

    // null, LOGIN, FORGOT or REQUEST
    const [showLoginState, setShowLoginState] = useState("LOGIN");

    //datos del forgotpass
    const [code, setCode] = useState(0);
    const [newPass, setNewPass] = useState("");
    const [rePass, setRePass] = useState("");

    function setAllFalse (objectState) {
        //Set all constant in objectState as false
        for (const key in objectState) {
            objectState[key] = false;
        }
    }

    async function resetSubmit() {
        if (newPass === null || newPass.length < MIN_PASS_LENGTH) {
            setAllFalse(showState);
            setShowState((state) => ({ ...state, showPassLength: true }));
            return;
        }
        if (newPass !== rePass) {
            setAllFalse(showState);
            setShowState((state) => ({ ...state, showPassMatch: true }));
            return;
        }
        try {

            await Auth.completeNewPassword(
                currentUser, newPass,
                {
                    name: currentUser.challengeParam.userAttributes.name,
                    family_name: currentUser.challengeParam.userAttributes.family_name,
                })
                .then(() => {
                    const sleepTime = 1000;
                    setShowPassForNewUser(false);
                    setShowLoginState("LOGIN");
                    setAllFalse(showState);
                    setShowState((state) => ({ ...state, showPassChanged: true }));
                    setTimeout( () => {
                        globalThis.location.href = "/";
                    }, sleepTime);
                });
        } catch (err) {
            console.log("error", err);
            if (err != null && err.code != null) {
                switch (err.code) {
                    case "ExpiredCodeException":
                        await Auth.forgotPassword(userEmail);
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showExpiredCodeException: true }));
                        break;
                    case "LimitExceededException":
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showLimitExceededException: true }));
                        break;
                    case "UserNotFoundException":
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showUserNotFoundException: true }));
                        break;
                    case "InvalidParameterException":
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showInvalidParameterException: true }));
                        break;
                    case "CodeMismatchException":
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showInvalidCode: true }));
                        break;
                    default:
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showDefaultError: true }));
                        break;
                }
            }
        }
    }

    async function signIn(prov) {
        switch (prov) {
            case "google":
                await Auth.federatedSignIn({ provider: "Google" })
                    .catch((err) => {
                        console.error(err);
                    });
                break;
            default:
                try {
                    if (userEmail === " " || userEmail === "" || userEmail === null || userEmail === undefined) {
                        setAllFalse(showUserState);
                        setShowUserState((state) => ({ ...state, showMsgEmail: true }));

                    } else if (userPass === " " || userPass === "" || userPass === null || userPass === undefined) {
                        setAllFalse(showUserState);
                        setShowUserState((state) => ({ ...state, showMsgPass: true }));
                    } else {
                        const user = await Auth.signIn(userEmail, userPass);
                        setCurrentUser(user);
                        if (user.challengeName === "SMS_MFA" ||
              user.challengeName === "SOFTWARE_TOKEN_MFA") {
                            console.log("You need to get the code from the UI inputs");
                            // You need to get the code from the UI inputs
                            // and then trigger the following function with a button click
                            const code = ""/* getCodeFromUserInput() */;
                            // If MFA is enabled, sign-in should be confirmed with the confirmation code
                            Auth.confirmSignIn(
                                user,   // Return object from Auth.signIn()
                                code,   // Confirmation code
                                user.challengeName === "SMS_MFA" ? "SMS_MFA" : "SOFTWARE_TOKEN_MFA"// MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
                            );
                        } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                            setShowLoginState(null);
                            setShowPassForNewUser(true);

                        } else if (user.challengeName === "MFA_SETUP") {
                            // This happens when the MFA method is TOTP
                            // The user needs to setup the TOTP before using it
                            // More info please check the Enabling MFA part

                            //Auth.setupTOTP(user);
                        }
                        console.log("Correct user");
                    }
                } catch (err) {
                    console.error(err);
                    if (err != null && err.code != null) {
                        switch (err.code) {
                            case "ExpiredCodeException":
                                await Auth.forgotPassword(userEmail);
                                setAllFalse(showState);
                                setShowState((state) => ({ ...state, showExpiredCodeException: true }));
                                break;
                            case "LimitExceededException":
                                setAllFalse(showState);
                                setShowState((state) => ({ ...state, showLimitExceededException: true }));
                                break;
                            case "UserNotFoundException":
                                setAllFalse(showState);
                                setShowState((state) => ({ ...state, showUserNotFoundException: true }));
                                break;
                            case "InvalidParameterException":
                                setAllFalse(showUserState);
                                setShowUserState((state) => ({ ...state, showInvalidUser: true }));
                                setShowState((state) => ({ ...state, showPassChanged: false }));
                                break;
                            case "CodeMismatchException":
                                setAllFalse(showState);
                                setShowState((state) => ({ ...state, showInvalidParameterException: true }));
                                break;
                            case "NotAuthorizedException":
                                setAllFalse(showUserState);
                                setShowUserState((state) => ({ ...state, showInvalidUser: true }));
                                setShowState((state) => ({ ...state, showPassChanged: false }));

                                break;
                            default:
                                setAllFalse(showState);
                                setShowState((state) => ({ ...state, showDefaultError: true }));
                                break;
                        }
                    }
                }
                break;
        }
    }


    async function handleChange(name, value) {
        switch (name) {
            case "usuario":
                if (value === null || value === "") {
                    value = " ";
                    await setUser(value);
                } else {
                    await setUser(value);
                }
                break;
            case "contraseña":
                await setPassword(value);
                break;
            case "code":
                if (value === null || value === "" || value === undefined) {
                    value = " ";
                    await setCode(value);
                } else {
                    await setCode(value);
                }
                break;
            case "newpassword":
                if (value === null || value === "") {
                    value = " ";
                    await setNewPass(value);
                } else {
                    await setNewPass(value);
                }
                break;
            case "repassword":
                if (value === null || value === "") {
                    value = " ";
                    await setRePass(value);
                } else {
                    await setRePass(value);
                }
                break;
            default:
                console.log("handlechange error");
        }
    }

    async function showForgot() {
        if (userEmail === null || userEmail === " ") {
            setShowUserState((state) => ({ ...state, showMsgEmail: true }));
        } else {
            try {
                await Auth.forgotPassword(userEmail);
                setShowLoginState("FORGOT");
                setShowState((state)=>({ ...state, showCodeSent: true}));
                setShowUserState((state) => ({ ...state, showInvalidUser: false }));

            } catch (err) {
                console.error(err);
                switch (err.code) {
                    case "LimitExceededException":
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showLimitExceededException: true }));
                        break;
                    case "UserNotFoundException":
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showUserNotFound: true, showUserNotFoundException: true }));
                        break;
                    case "InvalidParameterException":
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showInvalidParameterException: true }));
                        break;
                    case "CodeMismatchException":
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showPassMatch: true }));
                        break;
                    default:
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showDefaultError: true }));
                        break;
                }
            }
        }
    }

    // async function showRequest() {
    //     setShowLoginState("REQUEST");
    //     setShowUserState((state) => ({ ...state, showInvalidUser: false }));
    // }

    async function forgotSubmit() {
        if (code === null || code === "" || code === " " || code === undefined || code === 0) {
            setShowState((state) => ({ ...state, showUserNotFound: false,
                showCodeSent: false,
                showInvalidCode: false,
                showPassLength: false,
                showCodeException: false,
                showEnterCode: true,
                showPassChanged: false }));
            return;
        }
        if (code !== null && code !== "" && code.length > 0 && isNaN(code)) {
            setShowState((state) => ({ ...state, showUserNotFound: false,
                showCodeSent: false,
                showInvalidCode: true,
                showPassLength: false,
                showCodeException: false,
                showEnterCode: false,
                showPassChanged: false }));
            return;
        }
        if (newPass === null || newPass.length < MIN_PASS_LENGTH) {
            setShowState((state) => ({ ...state, showUserNotFound: false,
                showCodeSent: false,
                showInvalidCode: false,
                showPassLength: true,
                showCodeException: false,
                showEnterCode: false,
                showPassChanged: false }));
            return;
        }
        if (newPass !== rePass) {
            setShowState((state) => ({ ...state, showUserNotFound: false,
                showCodeSent: false,
                showInvalidCode: false,
                showPassLength: false,
                showCodeException: false,
                showEnterCode: false,
                showPassChanged: false,
                showPassMatch: true }));
            return;
        }

        try {
            await Auth.forgotPasswordSubmit(userEmail, code, newPass);
            setShowLoginState("LOGIN");
            setAllFalse(showState);
            setShowState((state) => ({ ...state, showPassChanged: true }));

        } catch (err) {
            console.error(err);
            if (err != null && err.code != null) {
                switch (err.code) {
                    case "ExpiredCodeException":
                        await Auth.forgotPassword(userEmail);
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showExpiredCodeException: true }));
                        break;
                    case "LimitExceededException":
                        setAllFalse(showState);
                        setShowState((state) => ({...state, showLimitExceededException: true}));
                        break;
                    case "UserNotFoundException":
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showUserNotFoundException: true }));
                        break;
                    case "InvalidParameterException":
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showInvalidParameterException: true }));
                        break;
                    case "CodeMismatchException":
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showCodeException: true }));
                        break;
                    default:
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showDefaultError: true}));

                        //SerializationException
                        break;
                }
            }
        }
    }

    function handleKeyDown(key) {
        if (key === "Enter") {
            signIn();
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs" sx={{ marginTop: -8 }}>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Typography component="h1" variant="h5" sx={{ color: "#757575" }}>
                        Get OpenAPI spec for our
                        <br />
                        <Link href={`${process.env.PUBLIC_URL}/versions/documentation/wiseconn-api-wiseconn-${latestVersion.value}-swagger.json`}>
                            {`Latest API version (${latestVersion.value})`}
                        </Link>
                    </Typography>
                    <Divider flexItem sx={{ marginY: 4 }} />
                    <Box
                        component="img"
                        sx={{
                            width: 250,
                        }}
                        alt="Wiseconn Logo"
                        src="img/wiseconn.png"
                        p={2}
                    />
                    <Typography component="h1" variant="h5" sx={{ color: "#757575" }}>
                        {showLoginState === "REQUEST" ? "Request Access" : "System Access"}
                    </Typography>

                    {showLoginState === "LOGIN" ?
                        <Box
                            component="form"
                            noValidate
                            sx={{ mt: 1 }}
                            onKeyDown={(e) => handleKeyDown(e.key)}
                        >
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="usuario"
                                label={t("login.pHolderUser")}
                                name="usuario"
                                value={userEmail}
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                autoComplete="email"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="contraseña"
                                label={pHolderPass}
                                type="password"
                                id="contraseña"
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                autoComplete="current-password"
                            />
                            <Typography component="p" align="center">
                                { }
                                {showUserState.showInvalidUser ? t("login.NotAuthorizedException") : null}
                                {showUserState.showMsgEmail ? t("login.enterMsgEmail") : null}
                                {showUserState.showMsgPass ? t("login.enterMsgPass") : null}
                                {showState.showCodeException ? t("login.CodeMismatchException") : null}
                                {showState.showExpiredCodeException ? t("login.ExpiredCodeException") : null}
                                {showState.showLimitExceededException ? t("login.LimitExceededException") : null}
                                {showState.showInvalidParameterException ? t("login.InvalidParameterException") : null}
                                {showState.showUserNotFoundException ? t("login.UserNotFoundException") : null}
                                {showState.showDefaultError ? t("login.defaultError") : null}
                                {showState.showPassChanged ? t("login.msgPassChanged") : null}
                            </Typography>
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3 }}
                                onClick={signIn}
                            >
                                {t("login.signin")}
                            </Button>
                            <Button
                                fullWidth
                                color="secondary"
                                variant="contained"
                                sx={{ mt: 1 }}
                                //onClick={showRequest}
                                href="https://docs.google.com/forms/d/e/1FAIpQLSd0oY9qs2DZ920gNIHVarThy-0A9RI1Yfb4S-13N1MjSMXc-A/viewform"
                            >
                                {t("login.request.title")}
                            </Button>
                            <Button
                                className="login-with-google-btn"
                                fullWidth
                                sx={{ mt: 1, color: "#757575" }}
                                onClick={() => signIn("google")}
                            >
                                {t("login.withGoogle")}
                            </Button>
                            <Button
                                fullWidth
                                variant="text"
                                sx={{ mt: 1 }}
                                onClick={showForgot}
                            >
                                {t("login.forgot")}
                            </Button>
                        </Box> : null}
                    {showLoginState === "REQUEST" ?
                        <RequestForm setShowLoginState={setShowLoginState} /> : null}
                    {showLoginState === "FORGOT" ?
                        <Box component="form" noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                disabled
                                fullWidth
                                id="usuario"
                                label={t("login.pHolderUser")}
                                name="usuario"
                                value={userEmail}
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                autoComplete="email"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="code"
                                label={pHolderCode}
                                inputProps={{
                                    autoComplete: "off"
                                }}
                                type="text"
                                id="code"
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="newpassword"
                                label={pHolderPass}
                                inputProps={{
                                    autoComplete: "new-password"
                                }}
                                type="password"
                                id="newpassword"
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="repassword"
                                label={pHolderPassConfirm}
                                autoComplete="off"
                                type="password"
                                id="repassword"
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                            />
                            <Typography component="p" align="center">
                                { }
                                {showState.showUserNotFound ? t("login.UserNotFoundException") : null}
                                {showState.showCodeSent ? t("login.msgForgotCodeSent") : null}
                                {showState.showEnterCode ? t("login.enterMsgCode") : null}
                                {showState.showInvalidCode ? t("login.msgInvalidCode") : null}
                                {showState.showPassLength ? t("login.msgPassLength") : null}
                                {showState.showPassMatch ? t("login.msgPassMatch") : null}
                                {showState.showCodeException ? t("login.CodeMismatchException") : null}
                                {showState.showExpiredCodeException ? t("login.ExpiredCodeException") : null}
                                {showState.showLimitExceededException ? t("login.LimitExceededException") : null}
                                {showState.showInvalidParameterException ? t("login.InvalidParameterException") : null}
                                {showState.showUserNotFoundException ? t("login.UserNotFoundException") : null}
                                {showState.showDefaultError ? t("login.defaultError") : null}
                            </Typography>
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3 }}
                                onClick={forgotSubmit}
                            >
                                {changePassword}
                            </Button>
                        </Box> : null}
                    {showPassForNewUser ?
                        <Box component="form" noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                disabled
                                fullWidth
                                id="usuario"
                                label={t("login.pHolderUser")}
                                name="usuario"
                                value={userEmail}
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                autoComplete="email"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="newpassword"
                                label={pHolderPass}
                                autoComplete="off"
                                type="password"
                                id="newpassword"
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="repassword"
                                label={pHolderPassConfirm}
                                autoComplete="off"
                                type="password"
                                id="repassword"
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                            />
                            <Typography component="p" align="center">
                                { }
                                {showState.showPassLength ? t("login.msgPassLength") : null}
                                {showState.showPassMatch ? t("login.msgPassMatch") : null}
                                {showState.showDefaultError ? t("login.defaultError") : null}
                            </Typography>
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3 }}
                                onClick={resetSubmit}
                            >
                                {changePassword}
                            </Button>
                        </Box> : null}
                </Box>
            </Container>
        </ThemeProvider>
    );
};


export default Login;
